import React, { Component } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "react-bootstrap"
import Buttons from "../components/paragraph/buttons"
import Heading from "../components/paragraph/heading"
import Paragraph from "../components/paragraph/paragraphs"

export default class PageHeader extends Component {
  render() {
    // console.log(this.props.data.data.relationships.field_slide_s)

    switch (this.props.data.type) {
      case "eck_page_header__image":
        return <HeaderImage data={this.props.data} />
      case "eck_page_header__video":
        return <HeaderVideo data={this.props.data} />
      default:
        return <HeaderText data={this.props.data} />
    }
  }
}

class HeaderText extends Component {
  render() {
    return (
      <section className={"dark gradient-light-blue"}>
        <Container>
          <Row>
            <Col>
              <h1>{this.props.data.title}</h1>
            </Col>
          </Row>
        </Container>
      </section>
    )
  }
}

class HeaderImage extends Component {
  render() {
    let header = this.props.data.data.relationships
    let image = header.field_image.relationships.field_media_image.localFile
    let image_portrait = header.field_image_portrait
      ? header.field_image_portrait.relationships.field_media_image.localFile
      : undefined
    let caption = header.field_caption

    return (
      <Container
        className={"d-flex c_PageHeader dark"}
        fluid={true}
        style={{
          minHeight: "calc(100vh - 96px)",
          position: "relative",
          overflow: "hidden",
        }}
      >
        {/* Landscape Image */}
        <GatsbyImage
          image={image.childImageSharp.gatsbyImageData}
          style={{
            position: "absolute",
            minWidth: "100%",
            minHeight: "100%",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
          className={image_portrait ? "d-none d-lg-block" : ""}
        />
        {/* Portrait Image */}
        {image_portrait && (
          <GatsbyImage
            image={image_portrait.childImageSharp.gatsbyImageData}
            style={{
              position: "absolute",
              minWidth: "100%",
              minHeight: "100%",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
            className={"d-lg-none"}
          />
        )}
        {/* Content */}
        <Container className={"m-auto"}>
          <Row className={"justify-content-start"}>
            <Col xs={12} md={8} xl={6}>
              {caption.map((caption, key) => {
                let type
                try {
                  type = caption.internal.type
                } catch (e) {}
                switch (type) {
                  case "paragraph__component_heading":
                    return (
                      <Heading
                        props={caption}
                        type={caption.field_heading_type}
                        key={key}
                      />
                    )
                  case "paragraph__component_paragraph_s":
                    return <Paragraph props={caption} key={key} />
                  case "paragraph__component_button_s":
                    return <Buttons props={caption} key={key} />
                  default:
                    return (
                      <p
                        key={key}
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          background: "white",
                        }}
                      >
                        Failed to load component!
                      </p>
                    )
                }
              })}
            </Col>
          </Row>
        </Container>
      </Container>
    )
  }
}

class HeaderVideo extends Component {
  render() {
    //console.log(this.props.data)
    let header = this.props.data.data.relationships
    let video =
      header.field_video.relationships.uid.relationships.media__video[0]
        .relationships.field_media_video_file.localFile.publicURL
    let poster = header.field_poster.relationships.field_media_image.localFile
    let poster_portrait = header.field_poster_portrait
      ? header.field_poster_portrait.relationships.field_media_image.localFile
      : undefined
    let caption = header.field_caption

    return (
      <Container
        className={"c_PageHeader dark"}
        fluid={true}
        style={{
          // height: "100vh",
          // maxHeight: "720px",
          // position: "relative",
          // overflow: "hidden",
          minHeight: "calc(100vh - 96px)",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <video
          playsInline
          autoPlay
          loop
          muted
          poster={poster.publicURL}
          className={"d-none d-lg-block"}
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            minWidth: "100%",
            minHeight: "100%",
          }}
        >
          <source src={video} type="video/mp4" />
        </video>

        {/* Landscape Image */}
        <GatsbyImage
          image={poster.childImageSharp.gatsbyImageData}
          style={{
            position: "absolute",
            minWidth: "100%",
            minHeight: "100%",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
          // alt={poster_portrait.field_image.field_media_image.alt}
          className={poster_portrait ? "d-none" : ""}
        />
        {/* Portrait Image */}
        {poster_portrait && (
          <GatsbyImage
            image={poster_portrait.childImageSharp.gatsbyImageData}
            style={{
              position: "absolute",
              minWidth: "100%",
              minHeight: "100%",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
            className={"d-lg-none"}
          />
        )}

        {/* Content */}
        <Row>
          <Container
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Row className={"justify-content-start"}>
              <Col xs={12} md={8} xl={6}>
                {caption.map((caption, key) => {
                  let type
                  try {
                    type = caption.internal.type
                  } catch (e) {}
                  switch (type) {
                    case "paragraph__component_heading":
                      return (
                        <Heading
                          props={caption}
                          type={caption.field_heading_type}
                          key={key}
                        />
                      )
                    case "paragraph__component_paragraph_s":
                      return <Paragraph props={caption} key={key} />
                    case "paragraph__component_button_s":
                      return <Buttons props={caption} key={key} />
                    default:
                      return (
                        <p
                          key={key}
                          style={{
                            color: "red",
                            fontWeight: "bold",
                            background: "white",
                          }}
                        >
                          Failed to load component!
                        </p>
                      )
                  }
                })}
              </Col>
            </Row>
          </Container>
        </Row>
      </Container>
    )
  }
}
