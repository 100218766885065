import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../layouts/default"
import SEO from "../components/seo"
import PageHeader from "./PageHeader"
import Bricks from "../components/structural/bricks"
import "./page.scss"

const PageTemplate = ({ data }) => {
  const pageNode = data.allNodePage.nodes[0]

  const eckHeader = {
    type: pageNode.relationships.field_page_header[0]
      ? pageNode.relationships.field_page_header[0].internal.type
      : null,
    data: pageNode.relationships.field_page_header[0],
    title: pageNode.title,
  }

  const eckBody = {
    depths: pageNode.field_page_body,
    bricks: pageNode.relationships.field_page_body,
  }

  // Handle Bricks formatting and mapping //
  const merge = (a, b) => {
    let rtn = []
    for (var i = 0; i < a.length; i++) {
      rtn.push({
        depth: a[i].depth,
        brick: b[i],
      })
    }
    return rtn
  }
  var input = merge(eckBody.depths, eckBody.bricks)
  var bricks = []
  var parents = [bricks]
  for (var el of input) {
    let nv = {
      type: el.brick.internal.type,
      id: el.brick.drupal_id,
      children: [],
    }
    parents[el.depth].push(nv)
    parents[++el.depth] = nv.children
  }
  // \END Handle bricks formatting and mapping //

  return (
    <Layout>
      {pageNode.relationships.field_seo ? (
        <SEO
          title={pageNode.relationships.field_seo.field_seo_title}
          description={pageNode.relationships.field_seo.field_seo_description}
          image={
            pageNode.relationships.field_seo.relationships &&
            pageNode.relationships.field_seo.relationships
              .field_seo_media_image &&
            pageNode.relationships.field_seo.relationships.field_seo_media_image
              .relationships.field_media_image.localFile.publicURL
          }
        />
      ) : (
        <SEO />
      )}
      <PageHeader data={eckHeader} />
      <Bricks bricks={bricks} />
    </Layout>
  )
}

export default PageTemplate

// BabelPluginRemoveGraphQLQueries:
// String interpolations are not allowed in graphql fragments.
// Included fragments should be referenced as `...MyModule_foo`.

export const query = graphql`
  query($id: String!) {
    allNodePage(filter: { id: { eq: $id } }) {
      nodes {
        id
        title
        field_page_body {
          depth
        }
        relationships {
          field_seo {
            field_seo_title
            field_seo_description
            relationships {
              field_seo_media_image {
                relationships {
                  field_media_image {
                    localFile {
                      publicURL
                    }
                  }
                }
              }
            }
          }
          field_page_body {
            ... on eck_page_body__section {
              drupal_id
              internal {
                type
              }
            }
            ... on eck_page_body__container {
              drupal_id
              internal {
                type
              }
            }
            ... on eck_page_body__column {
              drupal_id
              internal {
                type
              }
            }
          }
          field_page_header {
            ... on eck_page_header__image {
              internal {
                type
              }
              relationships {
                field_image {
                  field_media_image {
                    alt
                  }
                  relationships {
                    field_media_image {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(layout: FULL_WIDTH)
                        }
                      }
                    }
                  }
                }
                field_image_portrait {
                  field_media_image {
                    alt
                  }
                  relationships {
                    field_media_image {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(width: 768, layout: CONSTRAINED)
                        }
                      }
                    }
                  }
                }
                field_caption {
                  ... on paragraph__component_heading {
                    internal {
                      type
                    }
                    field_heading
                    field_heading_type
                  }
                  ... on paragraph__component_paragraph_s {
                    internal {
                      type
                    }
                    relationships {
                      field_paragraph_s {
                        field_paragraph
                      }
                    }
                  }
                  ... on paragraph__component_button_s {
                    internal {
                      type
                    }
                    relationships {
                      field_button_s {
                        field_link {
                          title
                          uri
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on eck_page_header__video {
              internal {
                type
              }
              relationships {
                field_video {
                  relationships {
                    uid {
                      relationships {
                        media__video {
                          relationships {
                            field_media_video_file {
                              localFile {
                                publicURL
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                field_poster {
                  field_media_image {
                    alt
                  }
                  relationships {
                    field_media_image {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(layout: FULL_WIDTH)
                        }
                      }
                    }
                  }
                }
                field_poster_portrait {
                  field_media_image {
                    alt
                  }
                  relationships {
                    field_media_image {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(width: 768, layout: CONSTRAINED)
                        }
                      }
                    }
                  }
                }
                field_caption {
                  ... on paragraph__component_heading {
                    internal {
                      type
                    }
                    field_heading
                    field_heading_type
                  }
                  ... on paragraph__component_paragraph_s {
                    internal {
                      type
                    }
                    relationships {
                      field_paragraph_s {
                        field_paragraph
                      }
                    }
                  }
                  ... on paragraph__component_button_s {
                    internal {
                      type
                    }
                    relationships {
                      field_button_s {
                        field_link {
                          title
                          uri
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
